.video {
  position: absolute;
  left: 30%;
  width: 70%;
  object-fit: cover;
  height: 100vh;
  position: fixed;
  z-index: -1;
}

.norights {
  color: white;
  height: 100vh;
  width: 100%;
  background: rgb(0, 0, 0);
  background: linear-gradient(
    70deg,
    rgba(0, 0, 0, 1) 0%,
    rgba(0, 0, 0, 0.9781162464985994) 47%,
    rgba(255, 255, 255, 0) 100%
  ); /*background: rgb(106,106,106);
      background: linear-gradient(70deg, rgba(106,106,106,1) 0%, rgba(106,106,106,0.9949229691876751) 51%, rgba(255,255,255,0) 100%);;
      */
}
.norights-container {
  margin-left: 3em;
  width: 50%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.norights-container-box {
  height: 60%;
  width: 90%;
  margin-top: 5em;
}

.norights-container-box-title {
  font-size: 2.5rem;
  font-family: 'Orbitron', sans-serif;
  font-weight: 900;
  display: flex;
}
.norights-bluedot {
  color: rgb(0, 91, 202);
  margin-left: 0.1em;
}
@media screen and (max-width: 800px) {
  .norights-container {
    margin-left: 0em;
    width: 100vw;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .norights-container-box {
    height: 60%;
    width: 100%;
    margin-top: 5em;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
  .norights-bluedot {
    display: none;
  }
}
@media screen and (max-width: 550px) {
  .norights-container-box-title {
    font-size: 2.5rem;
    font-family: 'Orbitron', sans-serif;
    font-weight: 900;
    display: flex;
    flex-direction: row;
    text-align: center;
  }
  .norights-bluedot {
    display: none;
  }
}
