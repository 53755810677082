.box-readmore::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background: linear-gradient(90deg, rgb(28, 27, 27) 0%, rgb(26, 23, 23) 100%);
}

.box-readmore::-webkit-scrollbar {
  width: 12px;
  background: linear-gradient(90deg, rgb(28, 27, 27) 0%, rgb(26, 23, 23) 100%);
}

.box-readmore::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: rgb(64, 64, 64);
}

.close-more {
  position: absolute;
  right: 0vw;
  margin-right: 0.7em;
  z-index: 99;
}

.credits {
  display: flex;
  align-items: center;
}

.icona-x-more {
  font-size: 1.7rem;
  color: red;
  cursor: pointer;
}

.readmore-logos {
  display: flex;
  flex-direction: row;
  height: 3em;
  gap: 1em;
}

.links {
  word-wrap: break-word;
}

.readmore-logos > .space {
  align-self: center;
}

@media screen and (max-width: 890px) {
  .readmore-logos {
    height: 2em;
    gap: 10px;
  }

  .readmore-logos > .space {
    height: 1.2em;
  }
}

@media screen and (max-width: 600px) {
  .readmore-logos {
    height: 1.5em;
    gap: 10px;
  }

  .readmore-logos > .space {
    height: 1em;
  }
}

@media screen and (max-width: 470px) {
  .readmore-logos {
    height: 1em;
    gap: 10px;
  }

  .readmore-logos > .space {
    height: 0.7em;
  }
}

@media screen and (max-width: 290px) {
  .readmore-logos {
    height: 0.8em;
    gap: 10px;
  }

  .readmore-logos > .space {
    height: 0.6em;
  }
}
