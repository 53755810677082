.header {
  height: 6em;
  width: 100%;
  position: absolute;
  display: flex;
  flex-direction: row;
  align-items: center;
  color: white;
}

.header-logo {
  display: flex;
  flex-direction: row;
  width: 17em;
  align-items: center;
  justify-content: space-between;
  margin-left: 2em;
  margin-right: 4em;
}
.header-logo:hover {
  cursor: pointer;
}
.header-logo-text {
  font-size: 1.5rem;
  font-family: 'Orbitron', sans-serif;
}
.header-logo-image {
  height: 3em;
}
.header-button {
  color: lightgray;
  transition: 0.3s;
  margin-right: 3em;
}
.header-button:hover {
  cursor: pointer;
  color: white;
}
.header-logout {
  position: absolute;
  right: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 6em;
  width: 5em;
}
.header-container {
  display: flex;
  flex-direction: row;
}
.nav-links {
  color: #fff;
  display: flex;
  align-items: center;
  text-decoration: none;
  height: 100%;
  font-size: 1rem;
}
.navbar {
  background: linear-gradient(90deg, rgb(28, 27, 27) 0%, rgb(26, 23, 23) 100%);
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
  position: sticky;
  top: 0;
  z-index: 999;
  font-weight: 400;
}
.novinka {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
  position: sticky;
  top: 4em;
  z-index: 999;
  background-color: rgb(158, 130, 66);
  cursor: pointer;
  text-align: center;
  padding: 0.4em;
}
.navbar-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80px;
  max-width: 1500px;
}

.navbar-logo {
  color: #fff;
  justify-self: start;
  margin-left: 0.5em;
  cursor: pointer;
  text-decoration: none;
  font-size: 1.6rem;
  display: flex;
  align-items: center;
  white-space: nowrap;
}

.nav-icon {
  margin-left: 0.3em;
  margin-bottom: 0.1em;
  font-size: 1.8rem;
}

.nav-menu {
  display: grid;
  grid-template-columns: repeat(4, auto);
  grid-gap: 10px;
  list-style: none;
  text-align: center;
  width: 60vw;
  justify-content: end;
  margin-right: 2rem;
}

.nav-item {
  height: 3.5em;
}

.nav-links {
  color: #fff;
  display: flex;
  align-items: center;
  text-decoration: none;
  height: 100%;
  font-size: 1rem;
}

.nav-links:hover {
  border-bottom: 4px solid #fff;
  transition: all 0.2s ease-out;
}

.fa-bars {
  color: rgb(255, 255, 255);
}

.nav-links-mobile {
  display: none;
}

.menu-icon {
  display: none;
}

@media screen and (max-width: 700px) {
  .header-logout {
    display: none;
  }
  .header > ul {
    padding: 0;
  }
  .menu-icon {
    display: block;
    position: absolute;
    top: 1em;
    right: 1em;
    font-size: 1.8rem;
    cursor: pointer;
    z-index: 50;
  }
  .nav-menu {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 100%;
    height: calc(100vh - 6em);
    position: absolute;
    top: 80px;
    left: -100%;
    opacity: 1;
    transition: all 0.5s ease;
  }

  .nav-menu.active {
    background: #000000fd;
    left: 0;
    opacity: 1;
    transition: all 0.5s ease;
    z-index: 999;
  }

  .nav-links {
    font-size: 1.5rem;
    text-align: center;
    width: 100%;
    display: table;
    margin-top: 1.5em;
  }

  .nav-links:hover {
    background-color: #fff;
    color: #242424;
    border-radius: 0;
  }

  .navbar-logo {
    float: left;
  }

  .navbar {
    justify-content: flex-start;
  }
  .navbar-container {
    float: left;
  }

  .fa-times {
    color: #fff;
    font-size: 2rem;
  }

  .nav-links-mobile {
    display: block;
    text-align: center;
    margin: 2rem auto;
    border-radius: 4px;
    width: 80%;
    text-decoration: none;
    font-size: 1.5rem;
    background-color: transparent;
    color: #fff;
    padding: 14px 20px;
    border: 1px solid #fff;
    transition: all 0.3s ease-out;
  }
  .nav-item {
    height: 3.5em;
  }
  .nav-links-mobile:hover {
    background: #fff;
    color: #242424;
    transition: 250ms;
  }
  .header {
    height: 6em;
    width: 100%;
    position: absolute;
    display: flex;
    flex-direction: row;
    align-items: center;
    color: white;
  }
}

@media screen and (max-width: 550px) {
  .header-logo-text {
    font-size: 1rem;
    font-family: 'Orbitron', sans-serif;
    margin-left: 1em;
  }
  .header-logo {
    display: flex;
    flex-direction: row;
    width: 17em;
    align-items: center;
    justify-content: flex-start;
    margin-left: 2em;
    margin-right: 4em;
  }
  .header-logo-image {
    height: 2.5em;
  }
}
