.Tlogo-container{
    display: flex;
    align-items: center;
    flex-direction: row;
    position: absolute;
    right: 2em;
    padding-inline: 1em;
    padding-bottom: 1em;
    z-index: 10;
}
.Tlogo-text{
margin-right: 1em;
color: gray;
}
.Tlogo-logo{
    height: 2em;
}
.bottom{
    bottom: 0;
}
.top{
    margin-top: 0.7em;
    top:1.8em
}
