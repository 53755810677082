.splash-screen {
  background-color: #000;
  color: #fff;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  flex-direction: column;
  font-family: Arial, Helvetica, sans-serif;
}

.splash-screen-home-logos img {
  height: 2em;
}

@keyframes fade-out {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

.hidden {
  animation: fade-out 2s ease-in-out forwards;
}

.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: auto;
  padding-top: 10%;
}

.loader {
  border: 4px solid #f3f3f3;
  border-top: 4px solid #3498db;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 2s linear infinite;
}

.splash-screen-nad {
  white-space: nowrap;
  position: relative;
  font-family: Raleway, sans-serif;
  font-size: 4rem;
  text-align: center;
  letter-spacing: 0.1em;
  -webkit-text-fill-color: transparent;
  -webkit-text-stroke-width: 3px;
  -webkit-text-stroke-color: white;
  text-shadow: 4px 4px #ff1f8f, 10px 10px #000;
}

.splash-screen-home-logos {
  display: flex;
  flex-direction: row;
  justify-content: center;
  position: absolute;
  width: 99vw;
  bottom: 1vh;
  height: 2em;
  gap: 30px;
  align-items: center;
}

.splash-screen-home-logos img {
  width: auto;
}

.splash-screen-space {
  height: 1.5em;
  margin-top: 0.2em;
}

.splash-screen-innovlab {
  margin-top: .1em;
}

@keyframes spin {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}

@media screen and (max-width: 1300px) {
  .splash-screen-nad {
    font-size: 3.3rem;
  }
}

@media screen and (max-width: 1080px) {
  .splash-screen-nad {
    left: 0;
  }

  .splash-screen-home-logos {
    align-items: center;
    bottom: 10px;
  }

  .splash-screen-home-logos img {
    height: 2em;
    margin-right: 1em;
  }
}

@media screen and (max-width: 670px) {
  .splash-screen-nad {
    font-size: 2.5rem;
  }

  .splash-screen-home-logos {
    justify-content: center;
    height: 1.7em;
    gap: 20px;
    width: 100vw;
  }

  .splash-screen-home-logos img {
    height: 1.5em;
    margin-right: .75em;
  }
}

@media screen and (max-width: 520px) {
  .splash-screen-nad {
    font-size: 1.8rem;
    margin-bottom: 0.3em;
  }

  .splash-screen-home-logos {
    height: 1.2em;
    gap: 5px;
  }

  .splash-screen-space {
    height: 1.2em;
    margin-top: 0;
  }

  .splash-screen-home-logos img {
    height: 1.25em;
    margin-right: .5em;
  }
}

@media screen and (max-width: 400px) {
  .splash-screen-nad {
    font-size: 1.5rem;
  }

  .splash-screen-space {
    height: 1.2em;
    margin-top: 0;
  }

  .splash-screen-home-logos {
    height: 1.2em;
    gap: 5px;
  }

  .splash-screen-home-logos img {
    height: 1em;
    margin-right: .25em;
  }
}

@media screen and (max-width: 366px) {
  .splash-screen-space {
    height: 0.7em;
    margin-top: 0;
    align-items: center;
  }

  .splash-screen-home-logos {
    height: 0.7em;
  }

  .splash-screen-home-name-section {
    height: auto;
    margin: 2em 0;
    margin-top: 3em;
  }

  .splash-screen-home-logos img {
    height: .75em;
    margin-right: .125em;
  }
}

@media screen and (max-width: 281px) {
  .splash-screen-nad {
    font-size: 1.3rem;
  }

  .splash-screen-space {
    height: 0.7em;
    margin-top: 0;
  }

  .splash-screen-home-logos {
    height: 0.7em;
    gap: 5px;
  }

  .splash-screen-home-logos img {
    height: .5em;
    margin-right: .6em;
  }
}

@media screen and (max-height: 476px) {
  .splash-screen-space {
    margin-top: 0;
    height: 1.2em;
  }

  .splash-screen-home-logos {
    height: 1.2em;
    gap: 5px;
  }
}