.video {
  position: absolute;
  left: 30%;
  width: 70%;
  object-fit: cover;
  height: 100vh;
  position: fixed;
  z-index: -1;
}

.login {
  color: white;
  height: 100vh;
  width: 100%;
  background: rgb(0, 0, 0);
  background: linear-gradient(
    70deg,
    rgba(0, 0, 0, 1) 0%,
    rgba(0, 0, 0, 0.9781162464985994) 47%,
    rgba(255, 255, 255, 0) 100%
  ); /*background: rgb(106,106,106);
        background: linear-gradient(70deg, rgba(106,106,106,1) 0%, rgba(106,106,106,0.9949229691876751) 51%, rgba(255,255,255,0) 100%);;
        */
}
.login-container {
  margin-left: 3em;
  width: 50%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.login-container-box {
  height: 60%;
  width: 90%;
  margin-top: 5em;
}
.login-container-box-guest {
  font-size: 0.7rem;
  align-self: center;
  display: flex;
  margin-top: 0.3em;
  color: rgb(166, 166, 166);
  transition: 0.3s;
}
.login-container-box-guest:hover {
  cursor: pointer;
  color: white;
}

.login-container-box-title {
  font-size: 2.5rem;
  font-family: 'Orbitron', sans-serif;
  font-weight: 900;
  display: flex;
}
.login-container-box-subtitle {
  color: lightgray;
  font-weight: 300;
  font-size: 0.9rem;
  margin-left: 0.2em;
  margin-bottom: 1em;
}
.login-container-box-subtitle-link {
  text-decoration: none;
  color: rgb(0, 91, 202);
  transition: 0.3s;
}
.login-container-box-subtitle-link:hover {
  color: rgb(0, 115, 255);
}
.login-container-box-inputs {
  width: 60%;
  display: flex;
  flex-direction: column;
}
.login-container-box-inputs-row {
  margin-top: 1.5em;
  height: 3.5em;
  display: flex;
  justify-content: center;
  gap: 10px;
}

.bluedot {
  color: rgb(0, 91, 202);
  margin-left: 0.1em;
}

.login-container-box-inputs-input label.MuiInputLabel-root {
  color: rgb(225, 225, 225);
}

.login-container-box-inputs-input {
  background: rgba(62, 62, 62, 0.32);
  border-radius: 12px;
}
.login-container-box-inputs-input > .MuiInputBase-root {
  color: white;
}
.login-container-box-inputs-input.Email,
.login-container-box-inputs-input.Password {
  border-radius: 12px;
}
.login-container-box-inputs-input.Firstname,
.login-container-box-inputs-input.Lastname {
  width: 50%;
}
.login-container-box-inputs-input.Password > .MuiInputBase-root > input {
  font-size: 0.8rem;
  color: white;
}
.login-container-box-inputs-input.Email > .MuiInputBase-root > input {
  font-size: 0.8rem;
  color: white;
}

.login-container-box-inputs-button.Register {
  border-radius: 24px;
  height: 3.5em;
  width: 13em;
}
.login-container-box-inputs-row.Register {
  display: flex;
  justify-content: center;
  margin-top: 2em;
}
fieldset.MuiOutlinedInput-notchedOutline > legend > span {
}
.login-container-box-inputs-input:hover {
  /*border: none;
    border-radius: 12px;
    border-style: solid;
    border-width: 0.1px;*/
}
.login-container-box-inputs-input fieldset.MuiOutlinedInput-notchedOutline {
  border-radius: 12px;
}
.MuiInput-underline:after {
  border-radius: 12px;
}
.login-container-box-inputs-input > .MuiInputBase-root > input:focus {
  outline: none;
}
.login-container-box-inputs-row > .MuiInputBase-root {
  border-radius: 12px;
}
.MuiFormHelperText-root.Mui-error {
  font-size: 0.5rem;
  margin-top: 1px;
}

@media screen and (max-width: 2000px) {
  .login-container {
    margin-left: 3em;
    width: 50em;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
@media screen and (max-width: 800px) {
  .login-container {
    margin-left: 0em;
    width: 100vw;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .login-container-box {
    height: 60%;
    width: 100%;
    margin-top: 5em;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
@media screen and (max-width: 550px) {
  .login-container-box-inputs {
    width: 90%;
    display: flex;
    flex-direction: column;
  }
  .login-container-box-title {
    font-size: 2.5rem;
    font-family: 'Orbitron', sans-serif;
    font-weight: 900;
    display: flex;
    flex-direction: row;
    text-align: center;
  }
}
@media screen and (max-width: 475px) {
  .bluedot {
    display: none;
  }
}
@media screen and (max-height: 550px) {
  .login {
    color: white;
    height: 100vh;
    width: 100%;
    background: rgb(0, 0, 0);
    background: linear-gradient(
      70deg,
      rgba(0, 0, 0, 1) 0%,
      rgba(0, 0, 0, 0.9781162464985994) 47%,
      rgba(255, 255, 255, 0) 100%
    ); /*background: rgb(106,106,106);
          background: linear-gradient(70deg, rgba(106,106,106,1) 0%, rgba(106,106,106,0.9949229691876751) 51%, rgba(255,255,255,0) 100%);;
          */
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .login-container {
    margin-left: 0em;
    width: 50em;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .login-container-box-title {
    font-size: 2.5rem;
    font-family: 'Orbitron', sans-serif;
    font-weight: 900;
    display: flex;
    justify-content: center;
    margin: 0em;
  }

  .login-container-box-inputs {
    width: 100%;
    display: flex;
    flex-direction: column;
  }
}
