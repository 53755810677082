.home-background {
  background-image: url('/public/images/home-background.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center top;
  height: 100vh;
  width: 100vw;
}
.home-background-gradient {
  background: rgb(0, 0, 0);
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 1) 0%,
    rgba(0, 0, 0, 0.6671918767507004) 50%,
    rgba(0, 0, 0, 0.4318977591036415) 100%
  );
  height: 100vh;
  width: 100vw;
}

.home {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.home-container {
  height: 100%;
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.home-container-title-2 {
  font-family: 'Orbitron', sans-serif;
  color: white;
  font-size: 2rem;
}
.home-container-title-1 {
  color: white;
  font-size: 3.5rem;
}
.home-container-box {
  margin-top: 1em;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1em;
  justify-content: space-around;
}
.home-container-box-guest {
  margin-top: 0.7em;
  color: rgb(166, 166, 166);
  font-size: 0.7rem;
  transition: 0.3s;
}
.home-container-box-guest:hover {
  cursor: pointer;
  color: white;
}
.home-container-box-button {
  width: 10em;
}
.home-container-box-button.Register {
  margin-left: 0.5em;
  background-color: rgb(59, 59, 59);
  color: white;
}
.home-container-box-button.Login {
  border-color: aliceblue;
  color: rgb(255, 255, 255);
}

@media screen and (max-width: 1024px) {
  .home {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex-direction: column;
  }
  .home-container {
    height: 100%;
    width: auto;
    display: flex;
    margin-top: 7em;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .home-background {
    background-image: url('/public/images/home-background.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: top;
    height: 100vh;
    width: 100vw;
  }
}

@media screen and (max-width: 550px) {
  .home-container-title-1 {
    color: white;
    font-size: 2.5rem;
    text-align: center;
  }
  .home-container-title-2 {
    font-family: 'Orbitron', sans-serif;
    color: white;
    font-size: 1rem;
  }
}

@media screen and (max-width: 360px) {
  .home-container-title-1 {
    color: white;
    font-size: 2.5rem;
    text-align: center;
  }
  .home-container-title-2 {
    font-family: 'Orbitron', sans-serif;
    color: white;
    font-size: 1rem;
  }
  .home-container-box {
    margin-top: 1em;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1em;
    justify-content: space-around;
  }
}
@media screen and (max-height: 380px) {
  .home-container {
    height: 100%;
    width: auto;
    display: flex;
    margin-top: 2em;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .add {
    margin-top: 6em;
  }
  .home-container-title-1 {
    color: white;
    font-size: 2.5rem;
    text-align: center;
  }
  .home-container-title-2 {
    font-family: 'Orbitron', sans-serif;
    color: white;
    font-size: 1rem;
  }
}
